import * as THREE from 'three'
import Experience from './Experience.js'

export default class Instructions
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.world = this.experience.world
        this.materials = this.experience.materials
        

        this.setModel()
    }

    setModel()
    {
        this.model = {}

        this.model.material = this.materials.whiteSignMaterial

        this.model.mesh = this.resources.items.textModel.scene.children[0]
        this.model.mesh.material = this.model.material
        this.scene.add(this.model.mesh)
    }

    update()
    {
    }

}