import { map } from 'lodash'
import * as THREE from 'three'
import bigScreenVertexShader from '../shaders/bigScreenShaders/vertex.glsl'
import bigScreenFragmentShader from '../shaders/bigScreenShaders/fragment.glsl'

import Experience from './Experience.js'

export default class TVScreen
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.world = this.experience.world
        this.materials = this.experience.materials
        this.time = this.experience.time
        
        this.setModel()
    }

    setModel()
    {
        this.model = {}

        /*
        // Video element settings
        this.model.element = document.createElement('video')
        this.model.element.muted = true
        this.model.element.loop = true
        this.model.element.controls = false
        this.model.element.playsInline = true
        this.model.element.autoplay = true
        this.model.element.src = '/assets/videoPortfolio.mp4'


        document.body.append(this.model.element)

        // Video texture
        this.model.texture = new THREE.VideoTexture(this.model.element)
        this.model.texture.encoding = THREE.sRGBEncoding

        // Video material
        this.model.material = new THREE.MeshBasicMaterial({
            map: this.model.texture
        })

        this.model.mesh = this.resources.items.tvScreenModel.scene.children[0]
        this.model.mesh.material = this.model.material
        this.scene.add(this.model.mesh)
        */

        
        /*
        // Classic jpg texture
        this.model.texture = this.resources.items.tvScreen_idle
        this.model.texture.encoding = THREE.sRGBEncoding
        this.model.texture.flipY = true

        this.model.material = new THREE.MeshBasicMaterial({ map: this.model.texture })
        */

        // TV static screen
        this.tvScreenMaterial = new THREE.ShaderMaterial({
            vertexShader: bigScreenVertexShader,
            fragmentShader: bigScreenFragmentShader,
            uniforms:{
                uTime: { value: 0},
                uXOffset: {value : 0.5},
                uYOffset: {value : 0.5},
                uRadialThickness: {value : 0.0001},
                uSpeed : {value: 0.00007},
                uLightColor: {value: new THREE.Color('#ffffff')},
                uDarkColor: {value: new THREE.Color('#000000')},
                uDefaultTexture: {value: this.resources.items.tvScreen_idel}, // modified texture
                uTexture1: {value: null },
                uTexture2: {value: null },
                uProgress: {value: 0 },
                uTexture1IsDefault: {value: 1.0},
                uTexture2IsDefault: {value: 0},
            }
        })

        // this.model.material = this.materials.tvScreenMaterial
        this.model.material = this.tvScreenMaterial

        this.model.mesh = this.resources.items.tvScreenModel.scene.children[0]
        this.model.mesh.material = this.model.material
        this.scene.add(this.model.mesh)
    }

    update()
    {
        // this.materials.tvScreenMaterial.uniforms.uTime.value = -this.time.elapsed * 0.001
    }

}