import * as THREE from 'three'
import Time from './Utils/Time.js'
import Sizes from './Utils/Sizes.js'
import Stats from './Utils/Stats.js'
import Debug from './Utils/Debug.js'
import Resources from './Resources.js'
import Materials from './Materials.js'
import Sounds from './Sounds.js'
import Renderer from './Renderer.js'
import Camera from './Camera.js'
import World from './World.js'
import RayCaster from './RayCaster.js'
import Controller from './Controller.js'
// import Navigation from './Navigation.js'

import assets from './assets.js'

export default class Experience
{
    static instance

    constructor(_options = {})
    {
        if(Experience.instance)
        {
            return Experience.instance
        }
        Experience.instance = this

        // Options
        this.targetElement = _options.targetElement

        if(!this.targetElement)
        {
            console.warn('Missing \'targetElement\' property')
            return
        }

        this.time = new Time()
        this.sizes = new Sizes()
        this.setConfig()
        this.setStats()
        this.setDebug()
        this.setScene()
        this.setCamera()
        this.setRenderer()
        this.sounds = new Sounds()
        this.resources = new Resources(assets)
        this.world = new World()
        this.materials = new Materials()
        this.controller = new Controller()
        this.rayCaster = new RayCaster()
        // this.setResources()
        // this.setWorld()
        // this.setMaterials()
        // this.setController()
        // this.setRayCaster()
        // this.setNaviagtion()
        
        this.sizes.on('resize', () =>
        {
            this.resize()
        })

        this.update()
    }

    setConfig()
    {
        this.config = {}
        this.config.touch = false
        window.addEventListener('touchstart', () =>
        {
            this.config.touch = true
        }, { once: true })

        // Debug
        this.config.debug = window.location.hash === '#debug'
        //this.config.debug = this.config.width > 420

        // Pixel ratio
        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        // Width and height
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height || window.innerHeight
        this.config.smallestSide = Math.min(this.config.width, this.config.height)
        this.config.largestSide = Math.max(this.config.width, this.config.height)

        if(this.sizes.width / this.sizes.height > 1) {this.config.vertical = false}
        else {this.config.vertical = true}

    }
  
    setStats()
    {
        if(this.config.debug)
        {
            this.stats = new Stats(true)
        }
    }
    
    setDebug()
    {
        if(this.config.debug)
        {
            this.debug = new Debug()
            //this.debug.containerElem_.style.width = '320px'
        }
    }

    setScene()
    {
        this.scene = new THREE.Scene()
    }

    setCamera()
    {
        this.camera = new Camera()
    }

    setRenderer()
    {
        this.renderer = new Renderer({ rendererInstance: this.rendererInstance })

        this.targetElement.appendChild(this.renderer.instance.domElement)
    }

    // setResources()
    // {
    //     this.resources = new Resources(assets)
    // }

    // setMaterials()
    // {
    //     this.materials = new Materials()
    // }

    // setWorld()
    // {
    //     this.world = new World()
    // }

    // setController()
    // {
    //     this.controller = new Controller()
    // }
    
    // setRayCaster()
    // {
    //     this.rayCaster = new RayCaster()
    // }
    
    // setNaviagtion()
    // {
    //     this.navigation = new Navigation()
    // }

    update()
    {
        if(this.stats)
            this.stats.update()
        
        this.camera.update()

        if(this.world)
            this.world.update()
        
        if(this.renderer)
            this.renderer.update()
        
        // if(this.navigation)
        //     this.navigation.update()

        window.requestAnimationFrame(() =>
        {
            this.update()
        })
    }

    resize()
    {
        // Config
        const boundings = this.targetElement.getBoundingClientRect()
        this.config.width = boundings.width
        this.config.height = boundings.height
        this.config.smallestSide = Math.min(this.config.width, this.config.height)
        this.config.largestSide = Math.max(this.config.width, this.config.height)

        this.config.pixelRatio = Math.min(Math.max(window.devicePixelRatio, 1), 2)

        if(this.camera)
            this.camera.resize()

        if(this.renderer)
            this.renderer.resize()

        if(this.world)
            this.world.resize()
    }

    destroy()
    {
        
    }
}
