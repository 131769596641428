import * as THREE from 'three'
import Experience from './Experience.js'
import chromaVertexShader from '../shaders/chromaShaders/vertex.glsl'
import chromaFragmentShader from '../shaders/chromaShaders/fragment.glsl'
import TransitionVertexShader from '../shaders/transitionShaders/vertex.glsl'
import TransitionFragmentShader from '../shaders/transitionShaders/fragment.glsl'
import SlideTransitionFragmentShader from '../shaders/transitionShaders/slideFragment.glsl'
import bigScreenVertexShader from '../shaders/bigScreenShaders/vertex.glsl'
import bigScreenFragmentShader from '../shaders/bigScreenShaders/fragment.glsl'

export default class Materials
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.debug = this.experience.debug
        this.scene = this.experience.scene
        this.world = this.experience.world
        this.config = this.experience.config

        this.mapColors()
        this.mapTextures()
        
    }

    mapColors()
    {
        // non-glowing lights
        this.greenSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#1EFF51')})
        this.redSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF0033')})
        this.whiteSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FFFFFF')})
        this.blackSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#000000')})
        this.pinkSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF2FD5')})
        this.blueSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#01DDFF')})
        this.orangeSignMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF5100')})
        this.redLedMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF112B')})
        this.greenLedMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#00FF00')})
        this.grayLedOffMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#585858')})
        this.grayLedOnMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FFFFFF')})

        // glowing lights
        this.neonYellowMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FFF668')})
        this.neonPinkMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF3DCB')})
        this.neonBlueMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#00BBFF')})
        this.poleLightMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#FF5EF1')})
        this.neonGreenMaterial = new THREE.MeshBasicMaterial({color: new THREE.Color('#56FF54')})
    } 

    mapTextures()
    {
        // Shader Materials
        
        // Big Screen

        /*
        // tv spinny screen
        this.tvScreenMaterial = new THREE.ShaderMaterial({
          vertexShader: bigScreenVertexShader,
          fragmentShader: bigScreenFragmentShader,
          uniforms:{
              uTime: { value: 0},
              uXOffset: {value : 0.268},
              uYOffset: {value : 0.648},
              uRadialThickness: {value : 4.0},
              uSpeed : {value: 0.3},
              uLightColor: {value: new THREE.Color('#00FFF0')},
              uDarkColor: {value: new THREE.Color('#05a7bd')},
              uDefaultTexture: {value: this.resources.items.tvScreen_about}, // modified texture
              uTexture1: {value: null },
              uTexture2: {value: null },
              uProgress: {value: 0 },
              uTexture1IsDefault: {value: 1.0},
              uTexture2IsDefault: {value: 0},
          }
        })
        */
        
        // TV static screen
        this.tvScreenMaterial = new THREE.ShaderMaterial({
          vertexShader: bigScreenVertexShader,
          fragmentShader: bigScreenFragmentShader,
          uniforms:{
              uTime: { value: 0},
              uXOffset: {value : 0.5},
              uYOffset: {value : 0.5},
              uRadialThickness: {value : 0.0001},
              uSpeed : {value: 0.00007},
              uLightColor: {value: new THREE.Color('#ffffff')},
              uDarkColor: {value: new THREE.Color('#000000')},
              uDefaultTexture: {value: this.resources.items.tvScreen_idel}, // modified texture
              uTexture1: {value: null },
              uTexture2: {value: null },
              uProgress: {value: 0 },
              uTexture1IsDefault: {value: 1.0},
              uTexture2IsDefault: {value: 0},
          }
        })

        /*
        // PC spinny screen
        this.pcScreenAMaterial = new THREE.ShaderMaterial({
          vertexShader: bigScreenVertexShader,
          fragmentShader: bigScreenFragmentShader,
          uniforms:{
              uTime: { value: 0},
              uXOffset: {value : 0.421},
              uYOffset: {value : 0.522},
              uRadialThickness: {value : 4.0},
              uSpeed : {value: 0.3},
              uLightColor: {value: new THREE.Color('#34fe81')},
              uDarkColor: {value: new THREE.Color('#386aff')},
              uDefaultTexture: {value: this.resources.items.projectMenuTexture}, // modified texture
              uTexture1: {value: null },
              uTexture2: {value: null },
              uProgress: {value: 0 },
              uTexture1IsDefault: {value: 1.0},
              uTexture2IsDefault: {value: 0},
          }
        })
        */
        this.pcScreenAMaterial = new THREE.ShaderMaterial({
          vertexShader: bigScreenVertexShader,
          fragmentShader: bigScreenFragmentShader,
          uniforms:{
              uTime: { value: 0},
              uXOffset: {value : 0.5},
              uYOffset: {value : 0.5},
              uRadialThickness: {value : 0.0001},
              uSpeed : {value: 0.000055},
              uLightColor: {value: new THREE.Color('#ffffff')},
              uDarkColor: {value: new THREE.Color('#000000')},
              uDefaultTexture: {value: this.resources.items.projectMenuTexture}, // modified texture
              uTexture1: {value: null },
              uTexture2: {value: null },
              uProgress: {value: 0 },
              uTexture1IsDefault: {value: 1.0},
              uTexture2IsDefault: {value: 0},
          }
        })

        // this.pcScreenBMaterial = new THREE.ShaderMaterial({
        //   vertexShader: bigScreenVertexShader,
        //   fragmentShader: bigScreenFragmentShader,
        //   uniforms:{
        //       uTime: { value: 0},
        //       uXOffset: {value : 0.421},
        //       uYOffset: {value : 0.522},
        //       uRadialThickness: {value : 4.0},
        //       uSpeed : {value: 0.3},
        //       uLightColor: {value: new THREE.Color('#34fe81')},
        //       uDarkColor: {value: new THREE.Color('#386aff')},
        //       uDefaultTexture: {value: this.resources.items.creditsScreenCreditsTexture}, // modified texture
        //       uTexture1: {value: null },
        //       uTexture2: {value: null },
        //       uProgress: {value: 0 },
        //       uTexture1IsDefault: {value: 1.0},
        //       uTexture2IsDefault: {value: 0},
        //   }
        // })

        this.pcScreenBMaterial = this.getTransitionShaderMaterial(this.resources.items.creditsScreenCreditsTexture)

        this.resources.trigger('texturesMapped')
    }

// https://discourse.threejs.org/t/basis-video-texture/12716

    getChromaKeyShaderMaterial(texture, color) {
    
        return new THREE.ShaderMaterial({
          side: THREE.FrontSide,
          transparent: true,
          uniforms: {
            map: {
              value: texture
            },
            keyColor: {
              value: color.toArray()
            },
            similarity: {
              value: 0.01
            },
            smoothness: {
              value: 0.0
            }
          },
          vertexShader: chromaVertexShader,
          fragmentShader: chromaFragmentShader
        });
      }
    
    getTransitionShaderMaterial(texture) {
  
      return new THREE.ShaderMaterial({
        side: THREE.FrontSide,
        uniforms: {
          texture1: {value: texture },
          progress: {value: 0 },
          texture2: {value: null },
        },
        // wireframe: true,
        vertexShader: TransitionVertexShader,
        fragmentShader: TransitionFragmentShader
      });
    }

    getSideScreenShaderMaterial(texture) {
  
      return new THREE.ShaderMaterial({
        side: THREE.FrontSide,
        uniforms: {
          texture1: {value: texture },
          progress: {value: 0 },
          texture2: {value: null },
        },
        // wireframe: true,
        vertexShader: TransitionVertexShader,
        fragmentShader: SlideTransitionFragmentShader
      });
    }
}
