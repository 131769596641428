export default [
    {
        name: 'base',
        data: {},
        items:
        [
            // google home model & texture
            { name: 'googleHomeLedMaskTexture', source: '/assets/googleHomeLedMask.png', type: 'texture' },
            { name: 'googleHomeLedsModel', source: '/assets/googleHomeLedsModel.glb', type: 'model' },

            // misc models
            { name: 'topChairModel', source: '/assets/topChairModel.glb', type: 'model' },
            { name: 'pcScreenAModel', source: '/assets/pcScreenAModel.glb', type: 'model' },
            { name: 'pcScreenBModel', source: '/assets/pcScreenBModel.glb', type: 'model' },
            { name: 'tvScreenModel', source: '/assets/tvScreenModel.glb', type: 'model' },

            // room model & texture
            { name: 'bakedTexture', source: '/assets/baked.jpg', type: 'texture' },
            { name: 'roomModel', source: '/assets/porfolio_room.glb', type: 'model' },
            { name: 'signsModel', source: '/assets/signsModel.glb', type: 'model' },
            { name: 'textModel', source: '/assets/textModel.glb', type: 'model' },

            // tvScreen textures
            { name: 'tvScreen_about', source: '/screenTextures/tvScreen_about.jpg', type: 'texture' },
            { name: 'tvScreen_skill', source: '/screenTextures/tvScreen_skill.jpg', type: 'texture' },
            { name: 'tvScreen_xp', source: '/screenTextures/tvScreen_xp.jpg', type: 'texture' },
            { name: 'tvScreen_idel', source: '/screenTextures/tvScreen_idel.jpg', type: 'texture' },

            // tvScreen textures (mobile)
            { name: 'tvScreen_about_mobile', source: '/screenTextures/tvScreen_about_mobile.jpg', type: 'texture' },
            { name: 'tvScreen_skill_mobile', source: '/screenTextures/tvScreen_skill_mobile.jpg', type: 'texture' },
            { name: 'tvScreen_xp_mobile', source: '/screenTextures/tvScreen_xp_mobile.jpg', type: 'texture' },

            // pcScreenA textures
            { name: 'projectsScreenDefaultTexture', source: '/screenTextures/projectsScreenDefaultTexture.jpg', type: 'texture' },
            { name: 'projectMenuTexture', source: '/screenTextures/projectMenuTexture.jpg', type: 'texture' },
            { name: 'project1Texture', source: '/screenTextures/project1Texture.jpg', type: 'texture' },
            { name: 'project2Texture', source: '/screenTextures/project2Texture.jpg', type: 'texture' },
            { name: 'project3Texture', source: '/screenTextures/project3Texture.jpg', type: 'texture' },
            { name: 'project4Texture', source: '/screenTextures/project4Texture.jpg', type: 'texture' },

            // pcScreenA textures (mobile)
            { name: 'projectMenuTexture_mobile', source: '/screenTextures/projectMenuTexture_mobile.jpg', type: 'texture' },
            { name: 'project1Texture_mobile', source: '/screenTextures/project1Texture_mobile.jpg', type: 'texture' },
            { name: 'project2Texture_mobile', source: '/screenTextures/project2Texture_mobile.jpg', type: 'texture' },
            { name: 'project3Texture_mobile', source: '/screenTextures/project3Texture_mobile.jpg', type: 'texture' },
            { name: 'project4Texture_mobile', source: '/screenTextures/project4Texture_mobile.jpg', type: 'texture' },

            // pcScreenB textures
            // { name: 'creditsScreenCreditsTexture', source: '/screenTextures/creditsScreenCreditsTexture.jpg', type: 'texture' },
            // { name: 'creditsScreenThanksTexture', source: '/screenTextures/creditsScreenThanksTexture.jpg', type: 'texture' },
            // { name: 'creditsScreenDefaultTexture', source: '/screenTextures/creditsScreenDefaultTexture.jpg', type: 'texture' },
        ]
    }
]