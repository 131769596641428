import Experience from './Experience.js'
import gsap from 'gsap'

export default class Controller
{
    constructor()
    {

        // Setup
        this.experience = new Experience()
        this.camera = this.experience.camera
        this.resources = this.experience.resources
        this.sounds = this.experience.sounds
        this.config = this.experience.config
        this.materials = this.experience.materials

        this.setLogic()
        this.setMenuControls()
        this.setAboutMeControls()
        this.setProjectControls()
        this.setCreditsScreenControls()
        this.setCamControls()
        this.setSocialControls()
    }

    setLogic()
    {
        this.logic = {}
        this.logic.buttonsLocked = false
        this.logic.mode = 'menu'

        this.logic.lockButtons = async (lockDuration) =>
        {
            this.logic.buttonsLocked = true
            await this.sleep(lockDuration)
            this.logic.buttonsLocked = false
        }
    }

    // Main menu controls

    setMenuControls()
    {
        this.menuControls = {}
        this.menuControls.projects = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.logic.mode = 'projects0'
                this.camControls.toProjects()

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        this.experience.world.screenA.pcScreenAMaterial,
                        this.resources.items.projectMenuTexture_mobile, // mod later
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        this.experience.world.screenA.pcScreenAMaterial,
                        this.resources.items.projectMenuTexture, // mod later
                        0.2
                    )
                }
            }
    
        }
        this.menuControls.home = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.sounds.playWhoosh()
                this.camera.transitions.home(1.5)
            }
        }

        this.menuControls.aboutMe = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.logic.mode = 'aboutMe'
                this.camControls.toAboutMe()

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_about_mobile,
                        0.2,
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_about,
                        0.2,
                    )
                }
            }
        }
        this.menuControls.credits = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'menu')
            {
                this.sounds.playClick()
                this.logic.mode = 'creditsStart'
                this.camControls.toCredits()
            }
        }
    }


    // About me tv screen controls

    setAboutMeControls()
    {
        this.aboutMeControls = {}

        this.aboutMeControls.aboutMeScreens = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                this.sounds.playClick()
                this.logic.mode = 'aboutMe'
                
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_about_mobile,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_about,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeSkills = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'experience'))
            {
                this.sounds.playClick()
                this.logic.mode = 'skills'

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_skill_mobile,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_skill,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeExperience = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills'))
            {
                this.sounds.playClick()
                this.logic.mode = 'experience'

                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_xp_mobile,
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.tvScreenMaterial,
                        this.experience.world.tvScreen.tvScreenMaterial,
                        this.resources.items.tvScreen_xp,
                        0.2
                    )
                }
            }
        }

        this.aboutMeControls.aboutMeBack = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'menu'
                this.camControls.toDefault()

                this.bigScreenTransition(
                    // this.materials.tvScreenMaterial,
                    this.experience.world.tvScreen.tvScreenMaterial,
                    this.resources.items.tvScreen_idel, // mod later to tvScreenDefaultTexture 
                    0.4,
                    1,
                    0
                )
            }
        }
    }

    // Project selection

    /**
     * pcScreenA is going to need 3 main materials:
     * 1. pcScreenADefaultTexture i.e. the default texture or idel texture
     * 2. projectMenuTexture i.e. the texture that is going to be display all projects
     * 3. project*Texture i.e. the texture of the project that is being displayed
     */
    setProjectControls()
    {
        this.projectControls = {}
        this.projectControls.project1 = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'projects0')
            {
                this.sounds.playClick()
                this.logic.mode = 'projects1'
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project1Texture_mobile, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project1Texture, // mod
                        0.2
                    )
                }
            }
        }
        this.projectControls.project2 = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'projects0')
            {
                this.sounds.playClick()
                this.logic.mode = 'projects2'
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project2Texture_mobile, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project2Texture, // mod
                        0.2
                    )
                }
            }
        }

        this.projectControls.project3 = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'projects0')
            {
                this.sounds.playClick()
                this.logic.mode = 'projects3'
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project3Texture_mobile, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project3Texture, // mod
                        0.2
                    )
                }
            }
        }

        this.projectControls.project4 = async () =>
        {
            if(this.logic.buttonsLocked === false && this.logic.mode === 'projects0')
            {
                this.sounds.playClick()
                this.logic.mode = 'projects4'
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project4Texture_mobile, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.project4Texture, // mod
                        0.2
                    )
                }
            }
        }

        // Go back
        this.projectControls.projectBack = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'projects0'))
            {
                this.sounds.playBloop()
                this.logic.lockButtons(1500)
                this.logic.mode = 'menu'
                this.camControls.toDefault()
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.projectsScreenDefaultTexture, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.projectsScreenDefaultTexture, // mod
                        0.2
                    )
                }
            }

            if(this.logic.buttonsLocked === false && (this.logic.mode === 'projects1' || this.logic.mode === 'projects2' || this.logic.mode === 'projects3' || this.logic.mode === 'projects4'))
            {
                this.sounds.playBloop()
                this.logic.mode = 'projects0'
                if(this.config.vertical === true)
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.projectMenuTexture_mobile, // mod
                        0.2
                    )
                }
                else
                {
                    this.bigScreenTransition(
                        // this.materials.pcScreenAMaterial, // mod
                        this.experience.world.screenA.pcScreenAMaterial, // mod
                        this.resources.items.projectMenuTexture, // mod
                        0.2
                    )
                }
            }
            console.log('projectBack')
        }

        // Enter
        this.projectControls.projectEnter = async () =>
        {
            console.log('projectEnter')
        }
    }

    //arcade screen credit controls

    setCreditsScreenControls()
    {
        this.screenControls = {}
        this.screenControls.creditsScreen = async () =>
        {
            /*
            if(this.logic.buttonsLocked === false && this.logic.mode === 'creditsStart' )
            {
                this.sounds.playClick()
                this.logic.mode = 'credits'
                this.screenTransition(
                    this.materials.pcScreenBMaterial,
                    this.resources.items.creditsScreenCreditsTexture,
                    0.2
                )
            }
            else if(this.logic.buttonsLocked === false && this.logic.mode === 'credits' )
            {
                this.sounds.playClick()
                this.logic.mode = 'thanks'
                this.screenTransition(
                    this.materials.pcScreenBMaterial,
                    this.resources.items.creditsScreenThanksTexture,
                    0.2
                )
            }
            else if(this.logic.buttonsLocked === false && this.logic.mode === 'thanks' )
            {
                this.sounds.playClick()
                this.logic.mode = 'menu'
                this.camControls.toDefault()
                this.screenTransition(
                    this.materials.pcScreenBMaterial,
                    this.resources.items.creditsScreenDefaultTexture,
                    0.2
                )
            }
            */
            // Click and get back for music
            if(this.logic.buttonsLocked === false && this.logic.mode === 'creditsStart' )
            {
                this.sounds.playClick()
                this.logic.mode = 'menu'
                this.camControls.toDefault()
            }
        }
    }

    setCamControls()
    {
        this.camControls = {}
        this.camControls.toProjects = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.pcScreenA(1.5)
            await this.sleep(1500)
            this.camera.camAngle.pcScreenA()
        }
        this.camControls.toDefault = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.default(1.5)
            await this.sleep(1500)
            this.camera.camAngle.default()
        }
        this.camControls.toAboutMe = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.tvScreen(1.5)
            await this.sleep(1500)
            this.camera.camAngle.tvScreen()
        }
        this.camControls.toCredits = async () =>
        {
            this.sounds.playWhoosh()

            this.logic.lockButtons(1500)
            this.camera.camAngle.unlocked()
            this.camera.transitions.pcScreenB(1.5)
            await this.sleep(1500)
            this.camera.camAngle.pcScreenB()
        }
    }

    setSocialControls()
    {
        this.socialControls = {}

        this.socialControls.linkedIn = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://www.linkedin.com/in/zaiyad-alsamarai/', '_blank');
            }
            
        }

        this.socialControls.gitHub = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.open('https://github.com/z-alsamarai', '_blank');
            }
            
        }


        this.socialControls.mail = async () =>
        {
            if(this.logic.buttonsLocked === false && (this.logic.mode === 'aboutMe' || this.logic.mode === 'skills' || this.logic.mode === 'experience'))
            {
                window.location.href='mailto:zalsamarai1998@gmail.com'
            }
            
        }
    }


    // transitions
    screenTransition(material,newTexture, duration,)
    {
        material.uniforms.texture2.value = newTexture
        gsap.to(material.uniforms.progress, {value:1,
            duration: duration,
            ease: "power1.inOut",
            onComplete: () => {
                material.uniforms.texture1.value = newTexture
                material.uniforms.progress.value = 0
            }
        })
    }

    bigScreenTransition(material,newTexture, duration, toDefault)
    {
        material.uniforms.uTexture2IsDefault.value = toDefault ? 1 : 0

        material.uniforms.uTexture2.value = newTexture
        gsap.to(material.uniforms.uProgress, {value:1,
            duration: duration,
            ease: "power1.inOut",
            onComplete: () => {
                material.uniforms.uTexture1IsDefault.value = toDefault ? 1 : 0 
                material.uniforms.uTexture1.value = newTexture
                material.uniforms.uProgress.value = 0
                
            }
        })
    }

    sleep(ms) 
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}