import * as THREE from 'three'
import Experience from './Experience.js'
import OfficeRoom from './OfficeRoom.js'
import GoogleLeds from './GoogleLeds.js'
import TopChair from './TopChair.js'
import PCScreenA from './PCScreenA.js'
import PCScreenB from './PCScreenB.js'
import TVScreen from './TVScreen.js'
import Signs from './Signs.js'
import Instructions from './Instructions.js'
import BouncingLogo from './BouncingLogo.js'

export default class World
{
    constructor(_options)
    {
        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        
        this.resources.on('groupEnd', (_group) =>
        {
            if(_group.name === 'base')
            {
                this.setOfficeRoom()
                this.setGoogleLeds()
                this.setTopChair()
                this.setPCScreenA()
                this.setPCScreenB()
                this.setTVScreen()
                this.setSigns()
                this.setInstructions()
                // this.setBouncingLogo()
            }
        })
    }

    setOfficeRoom()
    {
        this.officeRoom = new OfficeRoom()

    }

    setGoogleLeds()
    {
        this.googleLeds = new GoogleLeds()
    }

    setTopChair()
    {
        this.topChair = new TopChair()
    }

    setPCScreenA()
    {
        this.screenA = new PCScreenA()
    }

    setPCScreenB()
    {
        this.screenB = new PCScreenB()
    }

    setTVScreen()
    {
        this.tvScreen = new TVScreen()
    }

    setSigns()
    {
        this.signs = new Signs()
    }

    setInstructions()
    {
        this.instructions = new Instructions()
    }

    // setBouncingLogo()
    // {
    //     this.bouncingLogo = new BouncingLogo()
    // }


    resize()
    {
    }

    update()
    {
        if(this.googleLeds)
            this.googleLeds.update()

        if(this.topChair)
            this.topChair.update()

        // if(this.bouncingLogo)
        //     this.bouncingLogo.update()

        if(this.tvScreen)
            this.tvScreen.update()
        
        if(this.screenA)
            this.screenA.update()
        
        if(this.screenB)
            this.screenB.update()

        if(this.signs)
            this.signs.update()

        if(this.instructions)
            this.instructions.update()
    }

    destroy()
    {
    }
}